import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/index.js";
import BaseCardVue from "./components/BaseCard.vue";
import BaseHeaderVue from "./components/BaseHeader.vue";

const app = createApp(App);

app.use(store);

app.component("base-card", BaseCardVue);
app.component("base-header", BaseHeaderVue);

app.mount("#app");
