<template>
  <div>
    <div ref="chart"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist'

export default {
  data() {
    return {
      chartLayout: this.$store.getters.chartLayout,
    }
  },
  computed: {
    chartData() {
      return this.$store.getters.chartData
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler(newChartData) {
        this.drawChart(newChartData)
      }
    }
  },
  mounted() {
    this.calculateChartSize()
    this.drawChart()
    window.addEventListener('resize', this.calculateChartSize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateChartSize)
  },
  methods: {
    calculateChartSize() {
      const screenHeight = window.innerHeight
      const screenWidth = window.innerWidth
      this.chartLayout.height = screenHeight * 0.80 // Set height to 75% of screen height
      this.chartLayout.width = screenWidth * 0.80 // Set width to 75% of screen width
    },
    drawChart() {
      if (this.$refs.chart) {
        Plotly.react(this.$refs.chart, this.chartData, this.chartLayout);
      } else {
        this.$nextTick(() => {
          Plotly.react(this.$refs.chart, this.chartData, this.chartLayout);
        });
      }
    },
  },
}
</script>
