export default {
	changeFirstName(state, payload) {
		state.firstName = payload;
	},
	changeLastName(state, payload) {
		state.lastName = payload;
	},
	updataChartData(state, payload) {
		state.chartData = payload.chartData;
	},
	updateSearchType(state, payload) {
		state.searchType = payload;
	},
	fetchingError(state, payload) {
		state.fetchError = payload;
	},
	checkResult(state, payload) {
		if (payload.chartData[0].parents.length <= 1) {
			state.resultError = true;
		} else {
			state.resultError = false;
		}
	},
	resetResult(state) {
		state.resultError = false;
	},
	// SET_CHART_DATA(state, chartData) {
	// 	state.chartData = chartData;
	// },
};
