export default {
	firstName(state) {
		return state.firstName;
	},
	lastName(state) {
		return state.lastName;
	},
	chartData(state) {
		return state.chartData;
	},
	chartLayout(state) {
		return state.chartLayout;
	},
	fetchError(state) {
		return state.fetchError;
	},
	resultError(state) {
		return state.resultError;
	},
};
