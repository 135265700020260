<template>
  <div class="base-card">
    <slot></slot>
  </div>
</template>

<style scoped>
.base-card {
  display: inline-block;
  /* Ensure the card only takes the necessary width */
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 1rem;
  margin: 2rem auto;
  max-width: fit-content;
  /* Allow the card to expand based on content size */
}
</style>