<template>
  <base-header title="Sunsearch"></base-header>
  <base-card>
    <h1 style="text-align:center">Hello Sunsearchers</h1>
    <author-form></author-form>
  </base-card>
  <h1> {{ retultError }}</h1>
  <div>
    <base-card v-if="resultError">
      <h2 style="text-align:center;">There was no result for the author please try another name </h2>
    </base-card>
  </div>
  <div>
    <base-card v-if="fetchError">
      <h2 style="text-align:center;">There was a error with the data connection please try again later
      </h2>
    </base-card>
  </div>
  <div class="centered-container">
    <div class="chart-container" :style="chartContainerStyle">
      <base-card-plot v-if="!fetchError && !resultError">
        <!-- <h1 v-if="error">Error</h1> -->
        <PlotlySun></PlotlySun>
      </base-card-plot>
    </div>
  </div>
</template>

<script>
import AuthorForm from './components/AuthorForm.vue';
import PlotlySun from './components/PlotlySun.vue'
import BaseCardPlot from "./components/BaseCardPlot.vue"

export default {
  computed: {
    fetchError() {
      return this.$store.getters.fetchError
    },
    resultError() {
      return this.$store.getters.resultError
    }
  },
  components: {
    AuthorForm,
    PlotlySun,
    BaseCardPlot
  }
}

</script>

<style scoped>
.centered-container {
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.chart-container {
  width: 80%;
  /* Adjust the width as per your preference */
  margin: 0 auto;
}
</style>