import { createStore } from "vuex";
import getters from "./getters.js";
import actions from "./actions.js";
import mutations from "./mutations.js";

const store = createStore({
	state() {
		return {
			resultError: false,
			fetchError: false,
			firstName: "Test",
			lastName: "Me",
			chartData: [
				{
					type: "sunburst",
					ids: [
						"Your Name",
						"2020",
						"2021",
						"2022",
						"2023",
						"Journal1",
						"Journal2",
						"Journal3",
						"Journal4",
						"Journal5",
					],
					labels: [
						"Test me",
						"Year 2020",
						"Year 2021",
						"Year 2022",
						"Year 2023",
						"Nature",
						"Cell",
						"Lancet",
						"JAMA",
						"New England",
					],
					parents: [
						"",
						"Your Name",
						"Your Name",
						"Your Name",
						"Your Name",
						"2020",
						"2020",
						"2021",
						"2022",
						"2023",
					],
					values: [11, 5, 3, 2, 1, 4, 1, 3, 2, 1],
					marker: {
						colorscale: "Purbeles",
					},
				},
			],
			chartLayout: {
				colorscale: "Blues",
				height: null,
				width: null,
			},
		};
	},
	getters,
	actions,
	mutations,
});

export default store;