<template>
  <h3>{{ firstName }} {{ lastName }}</h3>

  <form @submit.prevent="gogoQuery">
    <input type="text" name="" id="" placeholder="First Name" v-model.trim="formFirstName" required>
    <input type="text" name="" id="" placeholder="Last Name " v-model.trim="formLastName" required>
    <br>
    <label for="Journals">Journals</label>
    <input type="radio" name="search" id="Journals" v-model="searchType" value="journal" checked>
    <label for="MeSH">MeSH</label>
    <input type="radio" name="search" id="MeSH" v-model="searchType" value="mesh">
    <br>
    <button>submit</button>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import config from '../lib/config'

export default {
  data() {
    return {
      searchType: "journal",
      formFirstName: "",
      formLastName: "",
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'firstName',
      'lastName'
    ]),
    chartData() {
      return this.$store.getters.chartData
    },
    resultError() {
      return this.$store.getters.resultError
    }
  },
  methods: {
    async gogoQuery() {

      this.$store.commit('resetResult')
      this.$store.commit('changeFirstName', this.formFirstName)
      this.$store.commit('changeLastName', this.formLastName)

      try {
        await fetch(config.apiBaseUrl + "sunburst/?searchType=" + this.searchType + "&firstName=" + this.formFirstName + "&lastName=" + this.formLastName)
          .then(response => response.json())
          .then(data => {
            this.$store.commit('checkResult', data)
            this.$store.commit('updataChartData', data)
          });
      }
      catch (error) {
        console.log(error)
        this.$store.commit("fetchingError", true)
      }
    },
    checkInput() {

    }
  }
}
</script>
